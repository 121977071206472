import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import {
  PlanType,
  QuotaType,
  SubscriptionModel,
} from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { DeleteTeamDirective } from '@app/+authenticated/shared/directives/delete-team.directive';
import { IconComponent as OldIconComponent } from '@app/+authenticated/shared/icon.component';
import { PermissionDirective } from '@app/+authenticated/shared/permission.directive';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { AppState } from '@app/reducers';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { PermissionState } from '@app/reducers/auth/auth.model';
import { getPermissionState, hasPermission } from '@app/reducers/auth/permission.helper';
import { DepartmentModel } from '@app/reducers/orm/department/department.model';
import { DepartmentService, getAllDepartmentsGroupedByLocation } from '@app/reducers/orm/department/department.service';
import { LocationModel } from '@app/reducers/orm/location/location.model';
import { getLocations, LocationService } from '@app/reducers/orm/location/location.service';
import { TeamModel, TeamType } from '@app/reducers/orm/team/team.model';
import { getActiveTeamsGroupedByDepartment } from '@app/reducers/orm/team/team.service';
import { SbSpinnerComponent } from '@app/shared/sb-spinner.component';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { ButtonComponent, DropdownModule, IconButtonComponent, IconComponent, SbDialogModule } from '@sb/ui';
import { hasReachedQuota, SubscriptionQuotaDirective } from '@shared/subscription-plan/subscription-quota.directive';
import groupBy from 'lodash-es/groupBy';
import { combineLatest, EMPTY, of, Subscription, switchMap } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'locations-table',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    OldIconComponent,
    IconComponent,
    FeatureFlagPipe,
    TranslationParamsPipe,
    IconButtonComponent,
    PermissionDirective,
    SubscriptionQuotaDirective,
    ButtonComponent,
    RouterModule,
    DropdownModule,
    IconButtonComponent,
    MatIconModule,
    SbSpinnerComponent,
    TooltipModule,
    SbDialogModule,
    DeleteTeamDirective,
  ],
  templateUrl: './locations-table.component.html',
})
export class LocationsTableComponent implements OnInit, OnDestroy {
  public locations: LocationModel[] = [];
  public departments: Dictionary<DepartmentModel[]> = {};

  public allLocations: LocationModel[] = [];
  public allDepartments: Dictionary<DepartmentModel[]> = {};
  public allTeams: Dictionary<TeamModel[]> = {};
  private dataSubs = new Subscription();

  public quotaType = QuotaType;

  public subscription: SubscriptionModel;

  public showArchivedDepartments = false;

  public menuItems: { label: string; command: () => void }[] = [];
  public hasReachedQuota: boolean;
  public hasReachedTeamQuota: boolean;
  public isFreePlan: boolean;
  public readonly TeamType = TeamType;

  public permissionState: PermissionState;

  public hasTargetDepartmentId: boolean;
  public hasTargetContractTeamId: boolean;

  public departmentsCount: number;

  public constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private locationService: LocationService,
    private departmentService: DepartmentService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      combineLatest([this.store.select(getAccountSubscription), this.store.select(getPermissionState)]).subscribe(
        ([subscription, permissionState]) => {
          this.menuItems = [];
          this.subscription = subscription;
          this.isFreePlan = subscription.plan.type === PlanType.FREE;
          this.hasReachedQuota = hasReachedQuota(QuotaType.DEPARTMENTS, subscription) && !this.isFreePlan;
          this.hasReachedTeamQuota = hasReachedQuota(QuotaType.TEAMS, subscription);

          this.permissionState = permissionState;

          const createTeamPermission = hasPermission(
            {
              userId: 'me',
              departments: 'any',
              permissions: ['Create teams'],
            },
            permissionState,
          );

          this.buildMenuItems(createTeamPermission, this.isFreePlan);
          this.cd.detectChanges();
        },
      ),
    );

    this.dataSubs.add(
      combineLatest([
        this.store.select(getLocations),
        this.store.select(getAllDepartmentsGroupedByLocation),
        this.store.select(getActiveTeamsGroupedByDepartment),
      ])
        .pipe(distinctUntilChanged())
        .subscribe(([locations, departments, teams]) => {
          this.allTeams = teams;
          this.allDepartments = departments;
          this.allLocations = locations;
          this.setLocations();
          this.cd.detectChanges();
        }),
    );

    this.hasTargetDepartmentId = this.departmentService.deactivateFields?.hasTargetDepartmentId;
    this.hasTargetContractTeamId = this.departmentService.deactivateFields?.hasTargetTeamId;
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  public setLocations() {
    if (this.showArchivedDepartments) {
      this.locations = this.allLocations;
      this.departments = this.allDepartments;
    } else {
      this.locations = this.allLocations.filter((location) => !location.deleted);
      this.departments = this.filterDeletedDepartments();
    }

    this.departmentsCount = Object.values(this.departments).reduce(
      (total, departmentItems) => total + departmentItems.length,
      0,
    );
  }

  public deactivateLocationGroup(id: string) {
    this.dataSubs.add(
      this.locationService.deactivate(id).subscribe({
        next: () => {
          this.cd.detectChanges();
        },
      }),
    );
  }

  public activateLocationGroup(id: string) {
    this.dataSubs.add(
      this.locationService.activate(id).subscribe({
        next: () => {
          this.cd.detectChanges();
        },
      }),
    );
  }

  public deactivateDepartment(id: string) {
    this.dataSubs.add(
      this.departmentService
        .validateDeactivate(id)
        .pipe(
          catchError((error) => {
            if (error.status === 422) {
              const hasValidationErrorsForTargetTeamId = 'targetTeamId' in error.error.meta.validation_errors;
              const hasValidationErrorsForTargetDepartmentId =
                'targetDepartmentId' in error.error.meta.validation_errors;

              this.departmentService.deactivateFields.hasTargetDepartmentId = hasValidationErrorsForTargetDepartmentId;
              this.departmentService.deactivateFields.hasTargetTeamId = hasValidationErrorsForTargetTeamId;

              if (hasValidationErrorsForTargetTeamId || hasValidationErrorsForTargetDepartmentId) {
                this.triggerDepartmentDeletionFlow(id);
              }
            }
            return EMPTY;
          }),
          switchMap(() => of(id)),
          switchMap((id) => {
            if (id) {
              return this.departmentService.deactivate(id);
            }
            return of(null);
          }),
        )
        .subscribe(() => {
          this.cd.detectChanges();
        }),
    );
  }

  public triggerDepartmentDeletionFlow(departmentId: string) {
    this.router.navigate(['', { outlets: { modal: ['deactivate-department', departmentId] } }]);
  }

  public activateDepartment(id: string) {
    this.dataSubs.add(
      this.departmentService.activate(id).subscribe({
        next: () => {
          this.cd.detectChanges();
        },
      }),
    );
  }

  public toggleShowArchivedDepartments() {
    this.showArchivedDepartments = !this.showArchivedDepartments;
    this.setLocations();
    this.cd.detectChanges();
  }

  private filterDeletedDepartments() {
    return groupBy(
      Object.entries(this.allDepartments)
        .filter(([locationId]) => !this.allLocations.find((location) => location.id === locationId).deleted)
        .map(([, departments]) => departments.filter((department) => !department.deleted))
        .flat(1),
      'location_id',
    );
  }

  private buildMenuItems(hasTeamPermission: boolean, isFreePlan: boolean): void {
    if (!isFreePlan) {
      this.menuItems = [
        {
          label: this.translate.instant('Create location'),
          command: () => this.router.navigate(['', { outlets: { modal: ['location-group'] } }]),
        },
      ];

      if (!this.hasReachedQuota) {
        this.menuItems.push({
          label: this.translate.instant('Create department'),
          command: () => this.router.navigate(['', { outlets: { modal: ['location'] } }]),
        });
      }
    }

    if ((hasTeamPermission || isFreePlan) && !this.hasReachedTeamQuota) {
      this.menuItems.push({
        label: this.translate.instant('Create team'),
        command: () => this.router.navigate(['', { outlets: { modal: ['team'] } }]),
      });
    }
  }
}
